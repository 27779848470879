import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Input
} from '@chakra-ui/react';
import './App.css';
import emailjs from 'emailjs-com';

function App() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef();
  const [successOpen, setSuccessOpen] = useState(false); // New state for success dialog
  const [showSeal, setShowSeal] = useState(false);
  const [reset, setReset] = useState(false);
  const [username, setUsername] = useState('');
  const [isYesOpen, setIsYesOpen] = useState(false);
  const form = useRef();

  const handleYesSubmit = (e) => {
    e.preventDefault();
    setIsYesOpen(false); // Close the input dialog first

    const inputs = form.current.querySelectorAll('input');
    inputs.forEach(input => {
      if (input.name === 'message_content') {
        input.value = `Instagram Username: ${username}`; // Update the input's value
      }
    });

    emailjs.sendForm('service_1w1s5i9', 'template_tyb9ws8', form.current, 'LOnZ4dDWASNQAyvl6')
      .then((result) => {
        console.log('Email successfully sent!', result.text);
        setSuccessOpen(true); // Show success dialog
      }, (error) => {
        console.log('Failed to send email:', error.text);
        // Here you could set a state to show an error message if desired
      });
  };


  useEffect(() => {
    setShowSeal(false);
    const sealTimer = setTimeout(() => {
      setShowSeal(true);
    }, 2000); // Start seal animation after text and buttons fade in

    return () => {
      clearTimeout(sealTimer);
    };
  }, [reset]);

  const handleResponse = (response) => {
    if (response === 'No') {
      onOpen();
    } else {
      setIsYesOpen(true);
    }
  };

  const handleReset = () => {
    setReset(!reset); // Toggle reset state to restart the site
    onClose();
  };

  const nyanCatIframe = {
    __html: '<iframe src="https://giphy.com/embed/gx54W1mSpeYMg" width="480" height="448" frameBorder="0" class="giphy-embed" allowFullScreen></iframe>'
  };

  return (
<VStack className="VStack" spacing={4} align="center" justify="center" minH="100vh"
        style={{ backgroundImage: 'url(/background.jpg)', backgroundSize: 'cover', position: 'relative' }}>
      <div className="nyancat">
        <Box dangerouslySetInnerHTML={nyanCatIframe} />
      </div>

      <Box className="fadeIn" position="relative" zIndex="100">
      <Text fontSize="4xl" textAlign="center" marginBottom="20px" zIndex="100">
        Dobrý den a brzy nashle!
      </Text>
      <Text fontSize="2xl" className="text-shadow" zIndex="100">Můžu tě pozvat na rande?</Text>
        
        {/* Button container */}
        <Box display="flex" justifyContent="center" mt={4}>
          <Button colorScheme="green" mr={3} onClick={() => handleResponse('Yes')}>ANO! 😍</Button>
          <Button colorScheme="red" onClick={() => handleResponse('No')}>Ne! ☠️</Button>
        </Box>
      </Box>

      {showSeal && <Image src="/seal.png" alt="Cute Seal" width="auto" height="100px" className="rotateAround" position="absolute" />}
      <Box position="absolute" top="0" right="0" bottom="0" left="0"/>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
        <AlertDialogContent color="white" bg="gray.800"> 
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              WHOOPS!
            </AlertDialogHeader>

            <AlertDialogBody>
  <Box display="flex" justifyContent="center" mb={4}>
    <Image src="/sad-seal.png" alt="Sad Seal" width="auto" height="150px" />
  </Box>
  Podívej, jak je z toho ten roztomilý tuleň smutný! 😢 Zkus to znovu...
</AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme="blue" ref={cancelRef} onClick={handleReset}>
                Okay
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog isOpen={isYesOpen} leastDestructiveRef={cancelRef} onClose={() => setIsYesOpen(false)}>
        <AlertDialogOverlay>
        <AlertDialogContent color="white" bg="gray.800"> 
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              WOOHOOOO!
            </AlertDialogHeader>

            <AlertDialogBody as="form" ref={form} onSubmit={handleYesSubmit}>
  <Box display="flex" justifyContent="center" mb={4}>
    <Image src="/happy-seal.png" alt="Happy Seal" width="auto" height="150px" />
  </Box>
  <Text mb={4}>Jak se jmenuješ na instagramu?</Text>
  <Input mt={4} name="message_content" placeholder="@username" onChange={(e) => setUsername(e.target.value)} />
  <Button mt={4} colorScheme="blue" type="submit">
    Pošli!
  </Button>
</AlertDialogBody>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      <AlertDialog isOpen={successOpen} onClose={() => setSuccessOpen(false)}>
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Úspěch!
        </AlertDialogHeader>

        <AlertDialogBody>
          Dík fík! @whostoletedsusername! ti brzy napíše! 😍
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button colorScheme="blue" onClick={() => setSuccessOpen(false)}>
            Close
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
    </VStack>
  );
}
export default App;